import { AppSettings } from '../constants/constants';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs'
import { catchError, map, share } from 'rxjs/operators';
import { HttpClient } from 'selenium-webdriver/http';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable(
   {
      providedIn: 'root'
   }
)
export class DhukanDataService {
   deviceInfo = null;
   ipAddress: any;

   constructor(private http: Http, private deviceService: DeviceDetectorService) {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      // this.fnGetIpAddress();
      console.log(this.deviceInfo)
   }

   fnpostInputParams(url, params) {
      const headers = new Headers({
         //'Content-Type': "application/JSON",
          'token': (sessionStorage.sptoken === undefined) ? '' : JSON.parse(sessionStorage.sptoken)                
      });


      return this.http.post(AppSettings.base_url + url, params, { headers: headers });
   }

 

   
     //Ticket 
     fnInsertTicket(params) {

      return this.fnpostInputParams(AppSettings.insertTicketUrl, params);
   }
   fnInsertTicketDetails(params) {

      return this.fnpostInputParams(AppSettings.insertTicketDetailsUrl, params);
   }
   
   fnSetTicket(params) {

      return this.fnpostInputParams(AppSettings.setTicketUrl, params);
   }
   fnSetTicketStatus(params) {

      return this.fnpostInputParams(AppSettings.setTicketStatusUrl, params);
   }

   getTicket(params) {

      return this.fnpostInputParams(AppSettings.getTicketUrl, params);
   }
   getTicketListview(params) {

      return this.fnpostInputParams(AppSettings.getTicketListviewUrl, params);
   }
   
   fnDeleteTicket(params) {

      return this.fnpostInputParams(AppSettings.deleteTicketUrl, params);
   }

   fnCheckTicket(params) {

      return this.fnpostInputParams(AppSettings.checkTicketUrl, params);
   }

   getLicenseListview(params) {

      return this.fnpostInputParams(AppSettings.getLicenseListviewUrl, params);
   }

   fnSetLicense(params) {

      return this.fnpostInputParams(AppSettings.setLicenseUrl, params);
   }

   //User 
   fnInsertUser(params) {

      return this.fnpostInputParams(AppSettings.insertUserUrl, params);
   }

   fnSetUser(params) {

      return this.fnpostInputParams(AppSettings.setUserUrl, params);
   }


   getUser(params) {

      return this.fnpostInputParams(AppSettings.getUserUrl, params);
   }


   fnDeleteUser(params) {

      return this.fnpostInputParams(AppSettings.deleteUserUrl, params);
   }

   fnCheckUser(params) {

      return this.fnpostInputParams(AppSettings.checkUserUrl, params);
   }

   getUserlogin(params) {

      return this.fnpostInputParams(AppSettings.getLoginUserUrl, params);
   }










  

   //Company 
   fnInsertCompany(params) {

      return this.fnpostInputParams(AppSettings.insertCompanyUrl, params);
   }

   fnSetCompany(params) {

      return this.fnpostInputParams(AppSettings.setCompanyUrl, params);
   }


   getCompany(params) {

      return this.fnpostInputParams(AppSettings.getCompanyUrl, params);
   }
   //Country
   getCountries() {
      const headers = new Headers({
         'Content-Type': "application/JSON",
         'X-CSCAPI-KEY': "cmZXSktRU2h5QWpYa1JnZVhYSldmUmhFc1pDZEZ2WDV5ZzVYMkQzRA=="
         // 'token': (sessionStorage.rbookadtoken === undefined) ? '' : JSON.parse(sessionStorage.rbookadtoken)                
      });


      return this.http.get("https://api.countrystatecity.in/v1/countries", { headers: headers });

   }
   getState(country) {
      const headers = new Headers({
         'Content-Type': "application/JSON",
         'X-CSCAPI-KEY': "cmZXSktRU2h5QWpYa1JnZVhYSldmUmhFc1pDZEZ2WDV5ZzVYMkQzRA=="
         // 'token': (sessionStorage.rbookadtoken === undefined) ? '' : JSON.parse(sessionStorage.rbookadtoken)                
      });


      return this.http.get("https://api.countrystatecity.in/v1/countries/" + country + "/states", { headers: headers });

   }

   fnDeleteCompany(params) {

      return this.fnpostInputParams(AppSettings.deleteCompanyUrl, params);
   }

   fnCheckCompany(params) {

      return this.fnpostInputParams(AppSettings.checkCompanyUrl, params);
   }





   //Branch 
   fnInsertBranch(params) {

      return this.fnpostInputParams(AppSettings.insertBranchUrl, params);
   }

   fnSetBranch(params) {

      return this.fnpostInputParams(AppSettings.setBranchUrl, params);
   }


   getBranch(params) {

      return this.fnpostInputParams(AppSettings.getBranchUrl, params);
   }

   fnDeleteBranch(params) {

      return this.fnpostInputParams(AppSettings.deleteBranchUrl, params);
   }

   fnCheckBranch(params) {

      return this.fnpostInputParams(AppSettings.checkBranchUrl, params);
   }



}


