
export const AppSettings = {

  
  //base_url: 'http://192.168.0.179:8000/',
  //base_url: 'http://200.1.50.108:8000/',
  //base_url: 'http://13.127.71.165:7000/',
  //base_url: 'http://200.1.50.102:8000/',

  base_url: 'https://apisupport.renoinfo.in/',

  
  //Ticket
  insertTicketUrl: 'ticket/insertticket',
  insertTicketDetailsUrl: 'ticket/insertticketdetails',
  setTicketUrl: 'ticket/setticket',
  setTicketStatusUrl: 'ticket/setticketstatus',
  checkTicketUrl: 'ticket/checkticket',
  getTicketUrl: 'ticket/getticket',
  deleteTicketUrl: 'ticket/deleteticket',
  getTicketListviewUrl: 'ticket/getticketlistview',
  
  getLicenseListviewUrl: 'ticket/getlicenselistview',
  setLicenseUrl: 'ticket/setlicensestatus',

  //User
  insertUserUrl: 'user/insertuser',
  setUserUrl: 'user/setuser',
  checkUserUrl: 'user/checkuser',
  getUserUrl: 'user/getuser',
  deleteUserUrl: 'user/deleteuser',
  getLoginUserUrl: 'user/getloginuser',
  getUserRoleRightsUserUrl: 'user/getuserrolerightsbyuserid',
 
 
 
  //Company
  insertCompanyUrl: 'company/insertcompany',
  setCompanyUrl: 'company/setcompany',
  checkCompanyUrl: 'company/checkcompany',
  getCompanyUrl: 'company/getcompany',
  deleteCompanyUrl: 'company/deletecompany',
  

  
  //Branch
  insertBranchUrl: 'branch/insertbranch',
  setBranchUrl: 'branch/setbranch',
  checkBranchUrl: 'branch/checkbranch',
  getBranchUrl: 'branch/getbranch',
  deleteBranchUrl: 'branch/deletebranch',
  


  
}





