import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddticketComponent } from './addticket/addticket.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit, AfterViewInit, OnDestroy {

  ticketColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Ticket";

  filterstatus: string = ""

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Project', field: 'projectname' },
    { headerName: 'Priority', field: 'periority' },
    { headerName: 'Message', field: 'message' },

    { headerName: 'Status', field: 'ticketstatus' },
    {
      headerName: 'Date', field: 'createdon', type: 'date',
    },

  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };

  ticketid: any = 0;
  innerticketColl: any = [];
  innerticketdetColl: any = [];
  innerticketBO: any = {};
  attachmentColl: any = [];

  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal, private datePipe: DatePipe,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }

      else {
        this.fnUserFailedForm();
      }


    });
  }


  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetTicket();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetTicket() {

    if (this.showProgress) {
      return;
    }
    this.fnShowProgress();
    var data = {
      'ticketid': 0,
      'filterstatus': this.filterstatus,
      'companyid': this.loginService.fnGetCompanyId(),
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.getTicketListview(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.ticketColl = res.json().result;
            console.log(this.ticketColl)
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnAddTicket() {
    try {
      const modalRef = this.productFindService.open(AddticketComponent, { size: <any>'s', backdrop: 'static' });


      modalRef.componentInstance.ngOnInit();

      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

        this.fngetTicket();

      });


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnEditTicket(pBO) {
    try {
      this.ticketid = pBO.ticketid
      this.fngetTicketByTicketId();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(pBO) {
    try {
      var deptid = pBO.ticketid
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Ticket?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteTicket(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteTicket(deptid) {
    var data = {
      'ticketid': deptid,
    }
    this.appService.fnDeleteTicket(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The Ticket has been deleted successfully.");

            this.fnInsertActivityLog('DELETE', deptid, this.fnGetDescription(deptid));

            this.fngetTicket();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Get 
  fngetTicketByTicketId() {
    var data = {
      'ticketid': this.ticketid,
      'companyid': this.loginService.fnGetCompanyId(),
    }
    this.appService.getTicket(data)
      .subscribe(
        (res) => {
          console.log(res.json().result)
          var result = res.json().result;

          this.innerticketColl = result[0];
          this.innerticketdetColl = result[1];

          if (this.innerticketColl.length > 0) {
            var editdata = this.innerticketColl[0];
            this.innerticketBO = editdata;
          }

          var attach = result[2]
          
          
          this.attachmentColl=[]
          for (let abo of attach) {
            if (!abo.ticketdetailsid) {
              this.attachmentColl.push(abo)    
            }
          }


          for (let pbo of this.innerticketdetColl) {
            pbo.attachmentColl=[]
          }
          for (let pbo of this.innerticketdetColl) {
            for (let abo of attach) {
              if (abo.ticketdetailsid===pbo.ticketdetailsid) {
                pbo.attachmentColl.push(abo)    
              }
            }
          }

          console.log(this.innerticketdetColl)
          
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }





  private fnSetDataTable() {

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  // private fnExport() {

  //   const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'l', backdrop: 'static' });
  //   modalRef.componentInstance.doctype = this.formname;
  //   modalRef.componentInstance.columnDefs = this.columnDefs;
  //   modalRef.componentInstance.contentColl = this.TicketColl;
  //   modalRef.componentInstance.fnSetTable();
  //   modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


  //   });

  // }

  fnGetDescription(id) {
    const index = this.ticketColl.findIndex(sku => sku.ticketid === id);

    if (index) {
      return this.ticketColl[index].productgroup;
    }
    else {
      return "";
    }

  }
  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }
  public fnSearchDatatable(value) {


  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        // 'companyid': this.loginService.fnGetCompanyId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
