import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDataService } from '../services/dhukan/login-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  userBO:any={};
  userrolerightsColl:any=[];
  userView: any = {};
  
  restaurantname: any = "";
  restaurantcode: any = "";
  
  isadminview: boolean = false;
  isrestaruntview: boolean = false;
  issuperadminview: boolean = false;
  
  constructor(public router: Router,private loginService: LoginDataService) {
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    this.userBO = this.loginService.userBO; 
    this.loginService.serviceloginChange.subscribe((value) => {
        this.userView = value                 
        if(this.userView.userBO){                
          this.userBO = this.userView.userBO                
          


        }      
        
    }); 
  }

  ngOnInit() {
    if(sessionStorage.sptoken === undefined){
      this.router.navigate(['/Login']);
    }
    else{
      this.loginService.fnGetUserRoleList();
    }

   
  }




  fnLogout(){
    sessionStorage.removeItem("sptoken");
    this.router.navigate(['/Login']);
  }

  fnTickectClick(){

    this.router.navigate(['/']);
  }

}
