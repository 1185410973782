import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addlicense',
  templateUrl: './addlicense.component.html',
  styleUrls: ['./addlicense.component.css']
})
export class AddlicenseComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  licenseColl: any = [];
  licenseid: number = 0
  companyColl: any = [];

  licenseBO: any = {};
  //pageColl:any=[];

  licenseform = new FormGroup({
    licenseid: new FormControl(0),  
    appname: new FormControl(null),
    systemcode: new FormControl(null),
    randomcode: new FormControl(null),    
    expirydate: new FormControl(null, Validators.required),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "license";


  @Input() licensename: any = '';
  @Output() emitData = new EventEmitter();

  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private activeModal: NgbActiveModal,private datePipe: DatePipe,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.licenseid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.licenseform.reset();
      this.fnInitializeLicense();

      if (this.licenseid > 0) {
        this.fngetLicense();
      }
     

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.licenseid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeLicense() {
    this.licenseform.setValue({
      licenseid: 0,     
      appname: null,
      systemcode:null,
      randomcode: null, 
      expirydate: null,
      inactive: false,
    })
  }

  //Get 
  fngetCompany() {
    var data = {
      'companyid': 0,

    }
    this.appService.getCompany(data)
      .subscribe(
        (res) => {
          this.companyColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetLicense() {
    var data = {
      'licenseid': this.licenseid,

    }

    

    this.appService.getLicenseListview(data)
      .subscribe(
        (res) => {
          console.log(res.json())
          this.licenseColl = res.json().result;
          var editdata = this.licenseColl[0];
          this.licenseBO=editdata
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.licenseform.valid) {
        //Check
        var data = {

          'licenseid': this.licenseid,
          'expirydate':this.fnFormatDatetime( this.licenseform.get('expirydate').value),          
          'inactive': this.licenseform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        if (this.licenseform.get('licenseid').value > 0) {
          //Update Department
          this.fnSetLicense(data);
        }
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

 
  //Set
  fnSetLicense(data) {

    this.appService.fnSetLicense(data).subscribe(
      (res) => {
        
        if (res.json().status===200) {
          this.toastr.success('', "The License has been updated successfully.");

         // this.fnInsertActivityLog('EDIT', this.licenseid, data.licensename);
          this.emitData.emit(this.licenseid);
          this.activeModal.close(true);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  public decline() {
    this.activeModal.close(false);
  }

  private fnEditMode(deptBO) {
    deptBO.expirydate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.expirydate));
    this.licenseform.setValue({
      licenseid: deptBO.licenseid,      
      systemcode: deptBO.systemcode,
      randomcode: deptBO.randomcode,
      appname: deptBO.appname,
      expirydate: deptBO.expirydate,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  
  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
