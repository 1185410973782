import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { AddlicenseComponent } from './addlicense/addlicense.component';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit , AfterViewInit, OnDestroy {

  licenseColl: any = [];
 
  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;
 
  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "License";
  filterby: String = "PENDING";
 
  searchtxt: any = "";
  columnDefs = [    
    { headerName: 'Application', field: 'appname' },
    { headerName: 'Company', field: 'companyname' },
    { headerName: 'Branch', field: 'branchname' },
    { headerName: 'System Code', field: 'systemcode' },
    { headerName: 'Random Code', field: 'randomcode' },
    { headerName: 'Expiry Date', field: 'expirydate', type: 'date' },
    { headerName: 'Created On', field: 'createdon', type: 'datetime' },
    { headerName: 'Sync On', field: 'syncon', type: 'datetime' },
    { headerName: 'Status', field: 'licensestatus' },
  ]
 
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };
 
  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }
 
  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
      
 
    });
  }
 
 
  ngOnInit() {
    try {
 
   
      if (this.loginService.fnCheckUser()) {
 
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }
    
 
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  fnOnInitForm() {
    this.fngetLicense();
  }
 
  fnCheckPrivilage() {
 
    this.isadd = false;
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete =false;
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }
 
  fnUserFailedForm() {
    this.isprivilage = true;
  }
 
 
 
  ngAfterViewInit(): void {
 
  }
 
  ngOnDestroy(): void {
 
  }
 
  fngetLicense() {
 
    if (this.showProgress) {
      return;
    }
 
    this.fnShowProgress();
    var data = {
      'licenseid': 0,
      'filterstatus':this.filterby
    }
    this.appService.getLicenseListview(data)
      .subscribe(
        (res) => {
 
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.licenseColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
 
 
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }
 
  fnAddLicense() {
    try {
      const modalRef = this.productFindService.open(AddlicenseComponent, { size: <any>'m', backdrop: 'static' });
 
 
      modalRef.componentInstance.ngOnInit();
 
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
        this.fngetLicense();
 
      });
 
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  fnEditLicense(pBO) {
   
    try {
     
      var deptid = pBO.licenseid
      if (!this.isedit) {
        return;
      }
      const modalRef = this.productFindService.open(AddlicenseComponent, { size: <any>'m', backdrop: 'static' });
      modalRef.componentInstance.licenseid = deptid;
 
      modalRef.componentInstance.ngOnInit();
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
        this.fngetLicense();
 
      });
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  public fnDeleteConfirm(pBO) {
    try {
      var deptid = pBO.licenseid
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete License?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteLicense(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  private fnDeleteLicense(deptid) {
   
  }
 
 
 
 
 
 
 
  private fnSetDataTable() {
 
  }
 
 
  fnPrintData() {
    try {
 
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  fnExportData() {
    try {
 
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  // private fnExport() {
 
  //   const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'l', backdrop: 'static' });
  //   modalRef.componentInstance.doctype = this.formname;
  //   modalRef.componentInstance.columnDefs = this.columnDefs;
  //   modalRef.componentInstance.contentColl = this.LicenseColl;
  //   modalRef.componentInstance.fnSetTable();
  //   modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
 
  //   });
 
  // }
 
  fnGetDescription(id) {
    const index = this.licenseColl.findIndex(sku => sku.licenseid === id);
 
    if (index) {
      return this.licenseColl[index].licensename;
    }
    else {
      return "";
    }
 
  }
 
  public fnSearchDatatable(value) {
 
 
  }
 
  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        // 'licenseid': this.loginService.fnGetLicenseId(),
      }
 
 
      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{
 
      //   });
 
    } catch {
 
    }
  }
 
 
 
  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }
 
 
  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }
 
 
 
 
}
