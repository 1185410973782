import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  show: boolean;
  //Edit Get Values
  userColl: any = [];
  userid: number = 0

  companyColl: any = [];


  userform = new FormGroup({
    userid: new FormControl(0),
    username: new FormControl('', Validators.required),
    pwd: new FormControl('', Validators.required),
    companyid: new FormControl(null, Validators.required),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "User";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.userid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.userform.reset();
      this.fnInitializeUser();

      if (this.userid > 0) {
        this.fngetUser();
      }
      this.fngetCompany();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.userid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeUser() {
    this.userform.setValue({

      userid: 0,
      username: '',
      pwd: '',
      companyid: null,
      inactive: false,
    })
  }

  fngetCompany() {
    var data = {
      'companyid': 0,

    }
    this.appService.getCompany(data)
      .subscribe(
        (res) => {
          this.companyColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetUser() {
    var data = {
      'userid': this.userid,
    }
    this.appService.getUser(data)
      .subscribe(
        (res) => {
          this.userColl = res.json().result;
          var editdata = this.userColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.userform.valid) {
        //Check
        var data = {
          'userid': this.userid,
          'username': this.userform.get('username').value,
          'pwd': this.userform.get('pwd').value,
          'companyid': this.userform.get('companyid').value,
          'inactive': this.userform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckUser(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.userform.get('userid').value > 0) {
                //Update Department
                this.fnSetUser(data);
              }
              else {
                //Insert Department
                this.fnInsertUser(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.userform.controls['username'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertUser(data) {

    this.appService.fnInsertUser(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new user has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.username);

          this.fnCloseUser();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetUser(data) {

    this.appService.fnSetUser(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The user has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.userid, data.username);

          this.fnCloseUser();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseUser() {
    try {
      this.router.navigate(['/User']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.userform.setValue({

      userid: deptBO.userid,
      username: deptBO.username,
      pwd: deptBO.pwd,
      companyid: deptBO.companyid,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }

  fnShowpassword(event) {

    this.show = !this.show;
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
