import { NgModule, } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';
import { TicketComponent } from './components/ticket/ticket.component';
import { CompanyComponent } from './components/company/company.component';
import { AddcompanyComponent } from './components/company/addcompany/addcompany.component';
import { TicketviewComponent } from './components/ticket/ticketview/ticketview.component';
import { BranchComponent } from './branch/branch.component';
import { LicenseComponent } from './components/license/license.component';


const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'Dashboard', component: TicketComponent },
  { path: 'Login', component: LoginComponent },
  { path: 'User', component: UserComponent },
  { path: 'AddUser', component: AdduserComponent },
  { path: 'Ticket', component: TicketComponent },
  { path: 'AddTicket', component: TicketviewComponent },
  { path: 'Company', component: CompanyComponent },
  { path: 'AddCompany', component: AddcompanyComponent },
  { path: 'Branch', component: BranchComponent },
  { path: 'License', component: LicenseComponent },
  
]; 


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false }), CommonModule],
  //imports: [RouterModule.forRoot(routes, { useHash: false })],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  //providers:[Location,{provide:LocationStrategy,useClass:PathLocationStrategy}]
})

export class AppRoutingModule { }
