import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddticketComponent } from '../addticket/addticket.component';
import { ReplyComponent } from '../reply/reply.component';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-ticketview',
  templateUrl: './ticketview.component.html',
  styleUrls: ['./ticketview.component.css']
})
export class TicketviewComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  @Input() aBO: any = {};
  //Edit Get Values
  @Input() ticketColl: any = [];
  @Input() ticketid: number = 0;

  @Input() ticketdetColl: any = {};
  @Input() attachmentColl: any = {};
  @Output() emitData = new EventEmitter();

  priorityColl: any = [];


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Ticket";



  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.ticketid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.ticketid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  public fnTicketStatus() {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Close?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnSetTicketStatus()
          }

        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  //Set
  fnSetTicketStatus() {
    
    var data = {
      'ticketid': this.ticketid,
      'ticketstatus': 'Closed',
      'modifiedby': this.loginService.fnGetModUserId(),
    }

    this.appService.fnSetTicketStatus(data).subscribe(
      (res) => {

        if (res.json().result === 200) {
          this.toastr.success('', "The Ticket has been updated successfully.");
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }
  fnSendingReply() {
    try {
      const modalRef = this.productFindService.open(ReplyComponent, { size: <any>'s', backdrop: 'static' });
      modalRef.componentInstance.ticketid = this.ticketid;

      modalRef.componentInstance.ngOnInit();

      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {



      });


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnEditTicket() {

    try {

      var deptid = this.ticketid

      const modalRef = this.productFindService.open(AddticketComponent, { size: <any>'m', backdrop: 'static' });
      modalRef.componentInstance.ticketid = deptid;

      modalRef.componentInstance.ngOnInit();
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      });

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  //Close

  fnCloseForm() {
    try {
      this.router.navigate(['/Ticket']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
