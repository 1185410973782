import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addbranch',
  templateUrl: './addbranch.component.html',
  styleUrls: ['./addbranch.component.css']
})
export class AddbranchComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  branchColl: any = [];
  branchid: number = 0
  companyColl: any = [];

  //pageColl:any=[];

  branchform = new FormGroup({
    branchid: new FormControl(0),
    branchname: new FormControl('', Validators.required),
    branchaddress: new FormControl(''),
    companyid: new FormControl(null, Validators.required),
    expirydt: new FormControl(null),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "branch";


  @Input() branchname: any = '';
  @Output() emitData = new EventEmitter();

  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private activeModal: NgbActiveModal,private datePipe: DatePipe,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.branchid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.branchform.reset();
      this.fnInitializeBranch();

      if (this.branchid > 0) {
        this.fngetBranch();
      }
      this.fngetCompany()

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.branchid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeBranch() {
    this.branchform.setValue({

      branchid: 0,
      branchname: '',
      branchaddress: '',
      companyid: null,
      expirydt: null,
      inactive: false,
    })
  }

  //Get 
  fngetCompany() {
    var data = {
      'companyid': 0,

    }
    this.appService.getCompany(data)
      .subscribe(
        (res) => {
          this.companyColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetBranch() {
    var data = {
      'branchid': this.branchid,

    }
    this.appService.getBranch(data)
      .subscribe(
        (res) => {
          this.branchColl = res.json().result;
          var editdata = this.branchColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.branchform.valid) {
        //Check
        var data = {

          'branchid': this.branchid,
          'branchname': this.branchform.get('branchname').value,
          'branchaddress': this.branchform.get('branchaddress').value,
          'expirydt':this.fnFormatDatetime( this.branchform.get('expirydt').value),
          'companyid': this.branchform.get('companyid').value,
          'inactive': this.branchform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckBranch(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.branchform.get('branchid').value > 0) {
                //Update Department
                this.fnSetBranch(data);
              }
              else {
                //Insert Department
                this.fnInsertBranch(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.branchform.controls['branchname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertBranch(data) {

    this.appService.fnInsertBranch(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Branch has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.branchid);
          this.emitData.emit(this.branchid);
          this.activeModal.close(true);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetBranch(data) {

    this.appService.fnSetBranch(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Branch has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.branchid, data.branchname);
          this.emitData.emit(this.branchid);
          this.activeModal.close(true);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  public decline() {
    this.activeModal.close(false);
  }

  private fnEditMode(deptBO) {
    deptBO.expirydt = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.expirydt));
    this.branchform.setValue({

      branchid: deptBO.branchid,
      branchname: deptBO.branchname,
      branchaddress: deptBO.branchaddress,
      companyid: deptBO.companyid,
      expirydt: deptBO.expirydt,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  
  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }



}
