import { Component, OnInit } from '@angular/core';
import { DhukanDataService } from '../services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
declare var google;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 

   isloading:boolean=false;
   isprivilage:boolean=false;  
 showProgress:boolean=false;
   formname:String="Dashboard";

   
  neworders:number=0;
  newcustomer:number=0;
  totalorders:number=0;
  totalcustomer:number=0;

  placedcount:number=0;
  topackcount:number=0;
  readycount:number=0;
  assigncount:number=0;
  pickedcount:number=0;
  deliveredcount:number=0;

  
  todaysales:number=0;
  todayvisitors:number=0;
  totalproducts:number=0;
  totalnodevice:number=0;

  recentviewers: number = 0;

  summaryColl:any=[];
  recentcustomerColl:any=[];
  mostviewproductsColl:any=[];
  ordersummaryColl:any=[];
  branchwiseColl:any=[];
  dailysalesColl: any = [];
  topsellingproductColl:any=[];
  dailysales: any[] = [];
  monthlywiseColl:any=[];
  billwiseColl:any[] = [];
  dailybillsales: any[] = [];

  view: any[] = [500, 350];
  view1: any[] = [700, 300];
  // options
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;

  multi: any[];


  
  constructor(private appService: DhukanDataService,public router: Router,private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,
    private loginService:LoginDataService,private datePipe: DatePipe) { 

      this.fnServiceChanges();
    }

    //User Check
    fnServiceChanges(){
      this.loginService.serviceloginChange.subscribe((value) => {
            if(this.loginService.fnCheckUser()){                
                this.fnCheckPrivilage();
                if(this.isview){
                  this.isloading=true;
                  this.fnOnInitForm();
                }
                else{
                  this.fnUserFailedForm();
                }    
            }
            else{
              this.fnUserFailedForm();
            }
      });
    }



    fnCheckPrivilage(){
      this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
      this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
      this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
      this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
      this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
      this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
    }

    fnUserFailedForm(){
      this.isprivilage=true;
    }

    fnOnInitForm(){
      this.fngetDasboard();

      this.fnLoadGeoLocation();
  
     
    }

  ngOnInit() {
  
    if(this.loginService.fnCheckUser()){         
      this.fnCheckPrivilage();
      if(this.isview){
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }          
    }
    else{
      this.loginService.fnGetUserRoleList();
    }
    
  }


  fngetDasboard() { 

      var data = {
          
      }
      // this.appService.fnGetDashboardAdmin(data)
      // .subscribe(
      //   (res)=>{              
      //     if(res.json().status==200){                          
      //      var _res=res.json().result;

      //      this.summaryColl=_res[0];

      //      if(this.summaryColl.length>0){
      //       this.neworders=this.summaryColl[0].neworders;
      //       this.newcustomer=this.summaryColl[0].newcustomer;
      //       this.totalorders=this.summaryColl[0].totalorders;
      //       this.totalcustomer=this.summaryColl[0].totalcustomer;

      //       this.todaysales=this.summaryColl[0].todaysales;
      //       this.totalproducts=this.summaryColl[0].totalproducts;
      //       this.totalnodevice=this.summaryColl[0].totalnodevice;
      //        this.todayvisitors = this.summaryColl[0].todayvisitors;
             
      //        this.recentviewers= this.summaryColl[0].recentviewers;
      //      }

      //      this.recentcustomerColl=_res[1];
      //      this.mostviewproductsColl=_res[2];

      //       this.ordersummaryColl=_res[3];
      //      if(this.ordersummaryColl.length>0){
      //       this.placedcount=this.ordersummaryColl[0].placedcount;
      //       this.topackcount=this.ordersummaryColl[0].topackcount;
      //       this.readycount=this.ordersummaryColl[0].readytopickcount;
      //       this.assigncount=this.ordersummaryColl[0].assigncount;
      //       this.pickedcount=this.ordersummaryColl[0].pickedcount;
      //       this.deliveredcount=this.ordersummaryColl[0].deliveredcount;
            
      //      }
   
      //      this.branchwiseColl=_res[4];
      //      this.dailysalesColl = _res[5];
      //      this.dailysales = [];
      //      this.dailysales.push({"name": "Daily","series": this.dailysalesColl})
           
          
      //      this.topsellingproductColl= _res[6];
      //      this.monthlywiseColl = _res[7];
            
      //      this.billwiseColl = _res[9];
      //      this.dailybillsales = [];
      //      this.dailybillsales.push({"name": "Daily","series": this.billwiseColl})
           
      //     }
      //     else if(res.json().status==400){                          
      //       this.toastr.error('Error', res.json().err_field);
      //     }                          
      // },
      //   (err)=>{                        
      //     this.toastr.error('Error', err);
      //  })
  }

  fnEditCustomer(deptBO) {
    try {
      if (this.isedit) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'id': deptBO.customerid,
          }
        }

        this.router.navigate(['/CustomerView'], navigationExtras);
      }

    } catch (ex) {
     // this.fnShowErrorMsg(ex);
    }
  }
  fnLoadGeoLocation(){

 
  }

    fnShowOrder(type) {
    try {
      if (this.isedit) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'filterstatus': type,
          }
        }

        this.router.navigate(['/Orders'], navigationExtras);
      }

    } catch (ex) {
     // this.fnShowErrorMsg(ex);
    }
  }

}
