import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageCompressService } from 'ngx-image-compress';
@Component({
  selector: 'app-addticket',
  templateUrl: './addticket.component.html',
  styleUrls: ['./addticket.component.css']
})
export class AddticketComponent implements OnInit {

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  projectColl: any = [];
  companyColl: any = [];

    //set image
    image;
    strImage: any;

  
  aBO: any = [];
  //Edit Get Values
  ticketColl: any = [];

  priorityColl: any = [];
  
  attachmentColl: any = [];


  ticketform = new FormGroup({
    ticketid: new FormControl(0),
    projectname: new FormControl('', Validators.required),
    periority: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Ticket";


  @Input() ticketid: number = 0;
  @Output() emitData = new EventEmitter();

  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private activeModal: NgbActiveModal,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService, private imageCompress: NgxImageCompressService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.ticketid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
     
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    
    });
  }

  ngOnInit() {
    try {
    
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      
      else {
        this.loginService.fnGetUserRoleList();
      }
   
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.ticketform.reset();
      this.fnInitializeTicket();

      if (this.ticketid > 0) {
        this.fngetTicket();
      }
    
      this.fngetProject();
      this.fngetPriority();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.ticketid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeTicket() {
    this.ticketform.setValue({

      ticketid: 0,
      projectname: '',
      periority: '',
      message: '',
     
      inactive: false,
    })
  }
  fngetPriority() {
    this.priorityColl=[];
    this.priorityColl.push({value:'LOW',displayname:'Low'})
    this.priorityColl.push({value:'MEDIUM',displayname:'Medium'})
    this.priorityColl.push({value:'HIGH',displayname:'High'})
  
   }

  fngetProject() {
   this.projectColl=[];
   this.projectColl.push({value:'RENOERP',displayname:'Reno ERP'})
   this.projectColl.push({value:'RENOPOSS',displayname:'Reno POSS'})
   this.projectColl.push({value:'RENOCRM',displayname:'Reno CRM'})
   this.projectColl.push({value:'RENOHRM',displayname:'Reno HRM'})
   this.projectColl.push({value:'BISTROSUIT',displayname:'BistroSuit'})
  }
  fngetCompany() {
    var data = {
      'companyid': 0,

    }
    this.appService.getCompany(data)
      .subscribe(
        (res) => {
          this.companyColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetTicket() {
    var data = {
      'ticketid': this.ticketid,

    }
    this.appService.getTicket(data)
      .subscribe(
        (res) => {
          this.ticketColl = res.json().result[0];
          var editdata = this.ticketColl[0];
          this.fnEditMode(editdata);
          this.aBO = editdata;

          var attach = res.json().result[2]
          
          this.attachmentColl=[]
          for (let abo of attach) {
            if (!abo.ticketdetailsid) {
              this.attachmentColl.push(abo)    
            }
          }
       
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.ticketform.valid) {
        //Check
        var data = {

          'ticketid': this.ticketid,
          'projectname': this.ticketform.get('projectname').value,
          'periority': this.ticketform.get('periority').value,
          'message': this.ticketform.get('message').value,
          'companyid': this.loginService.fnGetCompanyId(),          
          'inactive': this.ticketform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'attachmentColl':this.attachmentColl,
        }
        this.appService.fnCheckTicket(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.ticketform.get('ticketid').value > 0) {
                //Update Department
                this.fnSetTicket(data);
              }
              else {
                //Insert Department
                this.fnInsertTicket(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.ticketform.controls['projectname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertTicket(data) {

    this.appService.fnInsertTicket(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The new Ticket has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.ticketid);
          this.emitData.emit(this.ticketid);
          this.activeModal.close(true);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetTicket(data) {

    this.appService.fnSetTicket(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Ticket has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.ticketid, data.projectname);
          this.emitData.emit(this.ticketid);
          this.activeModal.close(true);
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  
  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }

  // Sku  Images
  removeimage(pictureindex) {


    if (pictureindex > -1) {

      if (this.attachmentColl[pictureindex].attachmentid > 0) {
        this.attachmentColl[pictureindex].isclear = 1;
      }
      else {
        this.attachmentColl.splice(pictureindex, 1);
      }

    }

  }

  

  private fnAttachmentImages(event) {
    try {

      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;

        for (let i = 0; i < filesAmount; i++) {

          var file: File = event.target.files[i];


          // if (file.size / 1000 > this.imagesize) {
          //   continue;
          // }

          const fileReader: FileReader = new FileReader();

          fileReader.onload = (event) => {

            this.image = fileReader.result;
            this.strImage = this.image.split(',')[1];

            this.imageCompress.compressFile(this.image, 75, 50).then(
              result => {
                this.strImage = result.split(',')[1];
                var _skuimages =
                {
                  ticketid: this.ticketid,
                  ticketdetailsid: 0,
                  attachmentid: 0,
                  image:this.strImage ,
                  strImage:result ,
                  isclear: 0,
                  picurl: ""
                }
                this.attachmentColl.push(_skuimages);
              }
            );

          }

          fileReader.readAsDataURL(event.target.files[i]);
        }




      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }

  }



  //Close
  public decline() {
    this.activeModal.close(false);
  }

  private fnEditMode(deptBO) {

    this.ticketform.setValue({

      ticketid: deptBO.ticketid,
      projectname: deptBO.projectname,
      periority: deptBO.periority,
      message: deptBO.message,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
