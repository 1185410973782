import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppSettings } from './services/constants/constants';

//Additional 
//Model
import { NgbModule, NgbDate } from '@ng-bootstrap/ng-bootstrap';
//progress bar
import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
//Toast Service
import { ToastrModule } from 'ngx-toastr';
//Select drop down
import { NgSelectModule } from '@ng-select/ng-select';
//Map
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
//EDITOR
import { CKEditorModule } from 'ngx-ckeditor';
//Table
import { DataTablesModule } from 'angular-datatables';
//Date Pikker
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
//Image Compress
import { NgxImageCompressService } from 'ngx-image-compress';
import { Ng2ImgMaxModule } from 'ng2-img-max';
//Barcode
import { NgxBarcodeModule } from 'ngx-barcode';
//Sidebar
import { SidebarModule } from 'ng-sidebar';
//Device Info
import { DeviceDetectorModule } from 'ngx-device-detector';
//Currency
import { CurrencyMaskModule, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";

//Firebase
// import {AngularFireModule} from 'angularfire2'
// import {AngularFireDatabaseModule} from 'angularfire2/database'
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

//Chart
import { NgxChartsModule } from '@swimlane/ngx-charts';

//Drag
import { DragDropModule } from '@angular/cdk/drag-drop';

//Ag Grid
import { AgGridModule } from 'ag-grid-angular';

import { NgxEmojiPickerModule } from 'ngx-emoji-picker';

import { NgxMaskModule, IConfig } from 'ngx-mask'


import { NgxPaginationModule } from 'ngx-pagination'; 

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BnNgIdleService } from 'bn-ng-idle'; 

//Component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';

// service
import { DhukanDataService } from './services/dhukan/dhukan-data.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';


import { TwoDigitDecimaNumberDirectiveDirective } from './two-digit-decima-number-directive.directive';
import { LoginComponent } from './components/login/login.component';
import { DateFormatPipe } from './date-format.pipe';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DatetimeFormatPipe } from './datetime-format.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { DisablecontrolDirective } from './disablecontrol.directive';
import { HttpcancelService } from './services/dhukan/httpcancel.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManageHttpInterceptor } from './interceptor/managehttp.interceptor';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore/angular-fire-firestore';
import { ImageformatterComponent } from './components/grid/imageformatter/imageformatter.component';
import { ButtonformatterComponent } from './components/grid/buttonformatter/buttonformatter.component';
import { ActionformatterComponent } from './components/grid/actionformatter/actionformatter.component';
import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';
import { RestaurantfindComponent } from './components/grid/restaurantfind/restaurantfind.component';
import { NgMaskCusDirectiveDirective } from './ng_mask_cus-directive.directive';
import { RenogridComponent } from './components/grid/renogrid/renogrid.component';
import { SortDirective } from './sort.directive';
import { TicketComponent } from './components/ticket/ticket.component';
import { AddticketComponent } from './components/ticket/addticket/addticket.component';
import { CompanyComponent } from './components/company/company.component';
import { AddcompanyComponent } from './components/company/addcompany/addcompany.component';
import { TicketviewComponent } from './components/ticket/ticketview/ticketview.component';
import { ReplyComponent } from './components/ticket/reply/reply.component';
import { BranchComponent } from './branch/branch.component';
import { AddbranchComponent } from './branch/addbranch/addbranch.component';
import { LicenseComponent } from './components/license/license.component';
import { AddlicenseComponent } from './components/license/addlicense/addlicense.component';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "left",
//   allowNegative: true,
//   decimal: ".",
//   precision: 2,
//   prefix: " ",
//   suffix: "",
//   thousands: ""
// };

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};



const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: "",
  separatorLimit: "9999999999",
  allowNegativeNumbers: false,
  dropSpecialCharacters:false,
  patterns:{
    'V': {pattern: new RegExp('-?')},
    '0': {pattern: new RegExp('[0-9]')}
  }
};
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavigationComponent,
    HeaderComponent,
    ConfirmationDialogComponent,
    TwoDigitDecimaNumberDirectiveDirective,
    LoginComponent,
    DateFormatPipe,
    DatetimeFormatPipe,
    LoadingComponent,
    DisablecontrolDirective,
    ImageformatterComponent,
    ButtonformatterComponent,
    ActionformatterComponent,
 
    UserComponent,
    AdduserComponent,
 
 
    RestaurantfindComponent,
 
    NgMaskCusDirectiveDirective,
    RenogridComponent,
    SortDirective,
    TicketComponent,
    AddticketComponent,
    CompanyComponent,
    AddcompanyComponent,
    TicketviewComponent,
    ReplyComponent,
    BranchComponent,
    AddbranchComponent,
    LicenseComponent,
    AddlicenseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    NgSelectModule,
    DragDropModule,
    CKEditorModule,
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    Ng2ImgMaxModule,
    NgxBarcodeModule,
    SidebarModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgxEmojiPickerModule.forRoot(),
    CurrencyMaskModule,
   
    NgxMaskModule.forRoot(maskConfig),
    ToastrModule.forRoot({
      timeOut: 500,
      positionClass: 'toast-top-center'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyClUICP4-qGf2r4SSCZF5MzbSFXT6mIbvM",    
      libraries: ["places"]
    }),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgxChartsModule,
    AgmDirectionModule,
    //AngularFireModule.initializeApp(AppSettings.firebaseConfig),
    AngularFireDatabaseModule,
    AgGridModule.withComponents([ImageformatterComponent, ButtonformatterComponent])
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe, NgxImageCompressService, DateFormatPipe, Location,
    { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true }, { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig }],
  bootstrap: [AppComponent],
  exports: [BrowserModule, TranslateModule],
  entryComponents: [RenogridComponent, ConfirmationDialogComponent, RestaurantfindComponent, AddticketComponent, AddcompanyComponent,
    ReplyComponent, AddbranchComponent,AddlicenseComponent],
})
export class AppModule { }
