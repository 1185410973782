import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../components/confirmation-dialog/confirmation-dialog.service';
import { DhukanDataService } from '../services/dhukan/dhukan-data.service';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { AddbranchComponent } from './addbranch/addbranch.component';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit, AfterViewInit, OnDestroy {

  branchColl: any = [];
 
  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;
 
  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Branch";
 
 
  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Branch', field: 'branchname' },
    { headerName: 'Address', field: 'branchaddress' },
    { headerName: 'Sync Code', field: 'synccode' },
      { headerName: 'Expiry Date', field: 'expirydt' ,type:'date' },
  ]
 
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };
 
  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }
 
  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
      
 
    });
  }
 
 
  ngOnInit() {
    try {
 
   
      if (this.loginService.fnCheckUser()) {
 
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }
    
 
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  fnOnInitForm() {
    this.fngetBranch();
  }
 
  fnCheckPrivilage() {
 
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }
 
  fnUserFailedForm() {
    this.isprivilage = true;
  }
 
 
 
  ngAfterViewInit(): void {
 
  }
 
  ngOnDestroy(): void {
 
  }
 
  fngetBranch() {
 
    if (this.showProgress) {
      return;
    }
 
    this.fnShowProgress();
    var data = {
      'branchid': 0,
      
    }
    this.appService.getBranch(data)
      .subscribe(
        (res) => {
 
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.branchColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
 
 
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }
 
  fnAddBranch() {
    try {
      const modalRef = this.productFindService.open(AddbranchComponent, { size: <any>'m', backdrop: 'static' });
 
 
      modalRef.componentInstance.ngOnInit();
 
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
        this.fngetBranch();
 
      });
 
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  fnEditBranch(pBO) {
   
    try {
     
      var deptid = pBO.branchid
      if (!this.isedit) {
        return;
      }
      const modalRef = this.productFindService.open(AddbranchComponent, { size: <any>'m', backdrop: 'static' });
      modalRef.componentInstance.branchid = deptid;
 
      modalRef.componentInstance.ngOnInit();
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
        this.fngetBranch();
 
      });
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  public fnDeleteConfirm(pBO) {
    try {
      var deptid = pBO.branchid
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Branch?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteBranch(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  private fnDeleteBranch(deptid) {
    var data = {
      'branchid': deptid,
    }
    this.appService.fnDeleteBranch(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The Branch has been deleted successfully.");
 
            this.fnInsertActivityLog('DELETE', deptid, this.fnGetDescription(deptid));
 
            this.fngetBranch();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
 
 
 
 
 
 
 
  private fnSetDataTable() {
 
  }
 
 
  fnPrintData() {
    try {
 
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  fnExportData() {
    try {
 
 
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  // private fnExport() {
 
  //   const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'l', backdrop: 'static' });
  //   modalRef.componentInstance.doctype = this.formname;
  //   modalRef.componentInstance.columnDefs = this.columnDefs;
  //   modalRef.componentInstance.contentColl = this.BranchColl;
  //   modalRef.componentInstance.fnSetTable();
  //   modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
 
  //   });
 
  // }
 
  fnGetDescription(id) {
    const index = this.branchColl.findIndex(sku => sku.branchid === id);
 
    if (index) {
      return this.branchColl[index].branchname;
    }
    else {
      return "";
    }
 
  }
 
  public fnSearchDatatable(value) {
 
 
  }
 
  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        // 'branchid': this.loginService.fnGetBranchId(),
      }
 
 
      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{
 
      //   });
 
    } catch {
 
    }
  }
 
 
 
  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }
 
 
  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }
 
 
 
 
} 